import styled, { css } from 'styled-components';

import { SECONDARY } from 'constants/colors';

export const MobileQuestionsContainer = styled.div`
  display: block;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const QuestionsContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
  }

  flex-direction: row;
  min-height: 370px;
  margin-bottom: 60px;
  flex-wrap: wrap;

  .faq__answer-enter {
    opacity: 0;
  }
  .faq__answer-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .faq__answer-exit-active {
    display: none;
    transition: opacity 300ms;
  }
`;

export const TabsNav = styled.div``;

export const Tab = styled.div`
  cursor: pointer;

  h3 {
    opacity: ${(props) => (props.isActive ? '0.8' : '0.4')};
  }
`;

export const TabContent = styled.div`
  padding-left: 30px;
`;

export const Title = styled.h3`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  opacity: 0.8;
  transition: all 300ms ease;
`;

export const Text = styled.p`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  opacity: 0.8;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.65;
`;
