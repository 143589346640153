import styled from 'styled-components';

import { TUNDORA, REGAL_BLUE, PRIMARY } from 'constants/colors';

export const Container = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;

  @media only screen and (min-width: 768px) {
    left: 40px;
    right: 40px;
  }
`;

export const QuestionNumber = styled.span`
  color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
  text-transform: uppercase;
  font-size: 18px;
`;

export const Title = styled.h4`
  font-size: 28px;
  font-weight: bold;
  line-height: 1.14;
  color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
  margin: 7px 0 20px 0;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 1.03;
  }
`;

export const SubTitle = styled.h5`
  font-size: 18px;
  line-height: 1.28;
  font-weight: 400;
  color: ${TUNDORA};
  margin: 20px 0;
`;

export const ElementWrapper = styled.div`
  padding: 0;
  padding-bottom: 60px;

  @media only screen and (min-width: 768px) {
    padding: 20px 60px 0 60px;
  }
`;
