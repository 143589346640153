import styled from 'styled-components';
import { REGAL_BLUE, TUNDORA, WHITE, BIG_STONE } from 'constants/colors';
import HeroImage from 'assets/images/hero-landing-bg.png';

export const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-image: url(${HeroImage});
  background-size: cover;
  background-position-y: bottom;
  justify-content: center;

  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media only screen and (min-width: 768px) {
    height: auto;
    min-height: 100vh;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  margin: 0;
  max-width: 100%;
  box-shadow: 0 2px 20px 0 ${BIG_STONE};
  padding: 20px 20px;
  min-height: 50vh;

  @media only screen and (min-width: 768px) {
    margin: 20px auto;
    height: 673px;
    width: 710px;
    border-radius: 4px;
    padding: 89px 120px;
  }
`;

export const Header = styled.h4`
  font-size: 36px;
  line-height: 1.03;
  font-weight: 600;
  color: ${REGAL_BLUE};
  margin: 20px 0;
  text-align: center;
`;

export const Checkmark = styled.span`
  font-size: 52px;
  font-weight: 400;
  color: ${REGAL_BLUE};
  text-align: center;
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${TUNDORA};
  margin: 0 0 20px 0;
`;
