import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';

import HeroImage from 'assets/images/hero-landing-bg.png';
import Commendations from '../../molecules/Commendations/Commendations';
import QuestionExample from '../../molecules/QuestionExample/QuestionExample';
import PartnerLogos from '../../molecules/PartnerLogos/PartnerLogos';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Section from '../../molecules/Section/Section';
import { Row, Column } from '../../../constants/grid';
import { Page, Logo, MobileImageWrapper, SampleReportImage } from './styles';
import { Header } from 'components/molecules/Header';
import Steps from '../../molecules/Steps/Steps';
import Hero from '../../molecules/Hero/Hero';
import Footer from '../../molecules/Footer/Footer';

import logo from 'assets/images/logo.svg';

import HeroReportExampleMobileImage from 'assets/images/hero-illustration-mobile.png';
require('assets/images/hero-illustration-mobile@2x.png');

class Landing extends PureComponent {
  openQuestionnaire = (e) => {
    this.props.history.push('/questionnaire');
  };

  render() {
    const { t, lng } = this.props;

    const steps = [
      {
        stepNumber: t('landing:steps.0.stepNumber'),
        title: t('landing:steps.0.title'),
        stepContent: t('landing:steps.0.stepContent'),
        callToAction: t('landing:steps.0.callToAction'),
        type: 'left',
        image: `/app/assets/images/step-1-${lng}.png`,
        mobileImage: `/app/assets/images/step-1-${lng}-mobile.png`,
      },
      {
        stepNumber: t('landing:steps.1.stepNumber'),
        title: t('landing:steps.1.title'),
        stepContent: t('landing:steps.1.stepContent'),
        callToAction: t('landing:steps.1.callToAction'),
        type: 'right',
        image: `/app/assets/images/step-2-${lng}.png`,
        mobileImage: `/app/assets/images/step-2-${lng}-mobile.png`,
      },
      {
        stepNumber: t('landing:steps.2.stepNumber'),
        title: t('landing:steps.2.title'),
        stepContent: t('landing:steps.2.stepContent'),
        callToAction: t('landing:steps.2.callToAction'),
        type: 'left',
        image: `/app/assets/images/step-3-${lng}.png`,
        mobileImage: `/app/assets/images/step-3-${lng}-mobile.png`,
      },
    ];

    const commendations = [
      {
        title: t('landing:commendations.0.title'),
        text: t('landing:commendations.0.text'),
        companyName: t('landing:commendations.0.companyName'),
        author: 'Friedrich E.',
        image: '/app/assets/images/testimonial-friedrich.png',
        alignment: 'left',
      },
      {
        title: t('landing:commendations.1.title'),
        text: t('landing:commendations.1.text'),
        companyName: t('landing:commendations.1.companyName'),
        author: 'Johannes K.',
        image: '/app/assets/images/testimonial-johannes.png',
        alignment: 'right',
      },
      {
        title: t('landing:commendations.2.title'),
        text: t('landing:commendations.2.text'),
        companyName: t('landing:commendations.2.companyName'),
        author: 'Michael H.',
        image: '/app/assets/images/testimonial-michael.png',
        alignment: 'left',
      },
    ];

    const footerNav = [
      {
        name: t('landing:footerNav.0.name'),
        link: 'https://valutico.com/about',
      },
      {
        name: t('landing:footerNav.1.name'),
        link: 'https://valutico.com/contact',
      },
      {
        name: t('landing:footerNav.2.name'),
        link: 'https://valutico.com/terms_of_use',
      },
      {
        name: t('landing:footerNav.3.name'),
        link: 'https://valutico.com/privacy_statement',
      },
      {
        name: t('landing:footerNav.4.name'),
        link: 'https://valutico.com/imprint',
      },
      {
        name: t('landing:footerNav.5.name'),
        link: 'https://valutico.com/career',
      },
      {
        name: t('landing:footerNav.6.name'),
        link: 'https://valutico.com/faq',
      },
    ];

    return (
      <>
        <Hero
          header={<Logo src={logo} />}
          background={HeroImage}
          heading={t('landing:hero:title')}
          subheading={t('landing:hero:subtitle')}
          callToAction={t('landing:hero:callToAction')}
          openQuestionnaire={this.openQuestionnaire}
        />
        <Header fixed={true} showButton={true} />
        <Page>
          <Row>
            <Column span="5">
              <PartnerLogos hasBorder={false} />
            </Column>
          </Row>
          <MobileImageWrapper>
            <SampleReportImage image={HeroReportExampleMobileImage} />
          </MobileImageWrapper>
          <Section titleAlignment="left" sectionTitle={t('landing:stepsTitle')} alignItems="column">
            <Steps openQuestionnaire={this.openQuestionnaire} steps={steps} />
          </Section>
          <Section titleAlignment="center" sectionTitle={t('landing:testimonialsTitle')} alignItems="row">
            <Commendations commendations={commendations} />
          </Section>
          <Section titleAlignment="left" sectionTitle={t('landing:questionsTitle')} alignItems="column">
            <QuestionExample questions={t('landing:questions')} />
          </Section>

          <Row>
            <Column span="5">
              <PartnerLogos hasBorder={true} />
            </Column>
          </Row>
        </Page>
        <CallToAction
          title={t('landing:callToActionTitle')}
          subtitle={t('landing:callToActionSubtitle')}
          buttonText={t('landing:shortCallToActionButtonText')}
          openQuestionnaire={this.openQuestionnaire}
        />
        <Footer footerNav={footerNav} />
      </>
    );
  }
}

export default translate()(Landing);
