import styled from 'styled-components';
import HeroImage from 'assets/images/hero-landing-bg.png';

import { WHITE, BIG_STONE, SECONDARY } from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  height: auto;
  background-image: url(${HeroImage});
  background-size: cover;
  background-position-y: bottom;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    min-height: 100vh;
  }
`;

export const QuestionBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  margin: 0;
  max-width: 100%;

  @media only screen and (min-width: 768px) {
    margin: 20px auto;
    height: 600px;
    width: 710px;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 ${BIG_STONE};
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 19px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 141px);
  overflow: auto;

  @media only screen and (min-width: 768px) {
    height: 550px;
    padding: 40px 46px;
    overflow: visible;
  }
`;

export const Loading = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  i {
    color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
    opacity: 0.4;
    font-size: 60px;
  }
`;
