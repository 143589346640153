import styled from 'styled-components';
import { Column } from '../../../constants/grid';

import { PRIMARY, SECONDARY } from 'constants/colors';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding: 35px 0;
  @media only screen and (min-width: 768px) {
    padding: 60px 0;
  }
`;

export const ImageColumn = styled(Column)`
  order: -1;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  @media only screen and (min-width: 768px) {
    order: inherit;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StepImage = styled.div`
  text-align: center;

  @media only screen and (min-width: 768px) {
    transition: all 1s ease;
    margin-left: ${(props) =>
      props.type == 'left' ? (props.revealSection ? '-70px' : 0) : props.revealSection ? 0 : '-25px'};
    opacity: ${(props) => (props.revealSection ? '1' : '0.3')};
  }
`;

export const Image = styled.img.attrs({
  src: (props) => props.image,
  srcSet: (props) => `${props.image} 1x, ${props.image.replace('.png', '@2x.png')} 2x`,
})`
  width: 100%;
  height: auto;
  align-self: center;
  @media only screen and (min-width: 768px) {
    width: 586px;
  }
`;

export const StepNumber = styled.p`
  color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

export const Title = styled.h2`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  font-size: 32px;
  font-weight: 700;
  line-height: 1.72;
  margin: 0 0 12px 0;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 35px 0;
  opacity: 0.8;
`;
