import React, { PureComponent } from 'react';
import Button from 'components/atoms/Button';
import { Row, Column } from '../../../constants/grid';
import i18next from 'i18next';

import {
  HeroContainer,
  Header,
  LanguageSelector,
  LanguageSelectorLink,
  Heading,
  Subheading,
  ContentWrapper,
  ImageWrapper,
  ReportExampleImage,
  ImageSubheading,
  Container,
} from './styles';

// images
import HeroReportExampleImageEn from 'assets/images/hero-landscape-book-en.png';
require('assets/images/hero-landscape-book-en@2x.png');
import HeroReportExampleImageDe from 'assets/images/hero-landscape-book-de.png';
require('assets/images/hero-landscape-book-de@2x.png');

class Hero extends PureComponent {
  redirect = () => {
    this.props.openQuestionnaire();
  };

  getHeroReportExampleImage = (locale) => {
    switch (locale) {
      case 'de':
        return HeroReportExampleImageDe;
        break;
      default:
        return HeroReportExampleImageEn;
        break;
    }
  };

  render() {
    const { header, background, heading, subheading, callToAction, imageSubheading } = this.props;
    const locales = i18next.options.whitelist.filter((loc) => loc != 'cimode');

    return (
      <HeroContainer background={background}>
        <Row>
          <Column span="5">
            <Header>
              {header}
              <LanguageSelector>
                {locales.map((lang) => (
                  <LanguageSelectorLink key={lang} href={`?locale=${lang}`}>
                    {lang.toUpperCase()}
                  </LanguageSelectorLink>
                ))}
              </LanguageSelector>
            </Header>
            <Container>
              <ContentWrapper>
                <Heading>{heading}</Heading>
                <Subheading dangerouslySetInnerHTML={{ __html: subheading }} />
                <Button variant="secondary" onClick={this.redirect}>
                  {callToAction}
                </Button>
              </ContentWrapper>
              <ImageWrapper>
                <ReportExampleImage image={this.getHeroReportExampleImage(i18next.language)} />
                <ImageSubheading dangerouslySetInnerHTML={{ __html: imageSubheading }} />
              </ImageWrapper>
            </Container>
          </Column>
        </Row>
      </HeroContainer>
    );
  }
}

export default Hero;
