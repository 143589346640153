import styled from 'styled-components';

import { PRIMARY, SECONDARY, TUNDORA } from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  order: -1;

  @media only screen and (min-width: 768px) {
    order: inherit;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px;
  background: linear-gradient(
    90deg,
    ${(props) => (props.secondaryColor ? props.secondaryColorLight + ', ' + props.secondaryColor : '#3f90af, #22546e')}
  );

  @media only screen and (min-width: 768px) {
    background: transparent;
    padding: 26px 46px;
  }
`;

export const TimeLeft = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    color: ${TUNDORA};
    font-size: 20px;
  }

  span {
    color: ${TUNDORA};
    margin-right: 5px;
    opacity: 0.5;
  }

  i {
    color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
    margin-left: 8px;
    margin-top: 3px;
  }
`;

export const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  margin: 10px 0 0 0;
  transition: all 400ms ease;
  border-radius: 4px;

  &:before {
    position: absolute;
    border-radius: 4px;
    content: '';
    display: block;
    bottom: 5px;
    left: 3px;
    right: 3px;
    height: 8px;
    opacity: 0.1;
    background-color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  }

  &:after {
    content: '';
    transition: all 400ms ease;
    display: block;
    border-radius: 4px;
    position: absolute;
    left: 3px;
    right: 3px;
    top: -3px;
    width: ${(props) => props.progress || 0}%;
    height: 8px;
    background-color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
  }
`;
