import styled from 'styled-components';
import { REGAL_BLUE, BONDI_BLUE, TUNDORA } from 'constants/colors';

export const Container = styled.div`
  padding: 20px 20px 70px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    padding: 80px 120px;
  }
`;

export const Header = styled.h4`
  font-size: 36px;
  line-height: 1.03;
  font-weight: 600;
  color: ${REGAL_BLUE};
  margin: 20px 0;
  text-align: center;
`;

export const ExclamationCircle = styled.span`
  font-size: 52px;
  font-weight: 400;
  color: ${(props) => (props.primaryColor ? props.primaryColor : BONDI_BLUE)};
  text-align: center;
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${TUNDORA};
  margin: 0 0 20px 0;
`;
